<template>
  <div class="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
    <div class="layout-container">
      <header id="header" class="shadow-none d-flex align-items-center">
        <div class="container d-flex justify-content-between" style="width: 90%">
          <div class="logo">
            <!-- Uncomment below if you prefer to use an text logo -->
            <h1>
              <a href="/">
                <b-img :src="img" alt="" rounded class="w-full ml-4" style="transform: scale(2.5)" /></a>
            </h1>
          </div>

          <nav id="navbar-public" class="navbar-public">
            <ul class="ul">
              <li class="li" style="margin: 0 10px">
                <b-link :to="{ name: 'home' }" class="nav-link scrollto" style="color: black;">
                  <strong>Beranda</strong>
                </b-link>
              </li>
              <li class="li" style="margin: 0 10px">
                <b-link :to="{ name: 'paket-tryout' }" class="nav-link scrollto" style="color: black;">
                  <strong>Kelas</strong>
                </b-link>
              </li>
              <li class="li" style="margin: 0 10px">
                <b-link :to="{ name: 'artikel' }" class="nav-link scrollto" style="color: black;">
                  <strong>Mading</strong>
                </b-link>
              </li>
              <li class="li mr-1" style="margin: 0 10px">
                <b-link :to="{ name: 'e-book' }" class="nav-link scrollto" style="color: black;">
                  <strong>E-book</strong>
                </b-link>
              </li>
              <user-dropdown class="mr-2" />
            </ul>
            <i class="fa-solid fa-bars mobile-nav-toggle mt-1"></i>
          </nav>
          <!-- .navbar -->
        </div>
      </header>
    </div>
  </div>
</template>

<script>
  import {
    BNav,
    BNavItem,
    BFormRadio,
    BImg,
    BLink,
    BNavbarNav,
    BNavbar,
  } from "bootstrap-vue";
  import NotificationDropdown from "./NotificationDropdown.vue";
  import UserDropdown from "./UserDropdown.vue";

  export default {
    components: {
      BNav,
      BNavItem,
      BFormRadio,
      BImg,
      BLink,
      BNavbar,
      // Navbar Components
      BNavbarNav,
      // Bookmarks,
      NotificationDropdown,
      UserDropdown,
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
      headerData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        img: require("@/assets/images/logo/bsh.svg"),
        selected: "center",
      };
    },
    mounted() {

      (function () {
        "use strict";
        /**
         * Easy selector helper function
         */
        const select = (el, all = false) => {
          el = el.trim();
          if (all) {
            return [...document.querySelectorAll(el)];
          } else {
            return document.querySelector(el);
          }
        };

        /**
         * Easy event listener function
         */
        const on = (type, el, listener, all = false) => {
          let selectEl = select(el, all);
          if (selectEl) {
            if (all) {
              selectEl.forEach((e) => e.addEventListener(type, listener));
            } else {
              selectEl.addEventListener(type, listener);
            }
          }
        };

        /**
         * Easy on scroll event listener
         */
        const onscroll = (el, listener) => {
          el.addEventListener("scroll", listener);
        };

        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select("#navbar-public .scrollto", true);
        const navbarlinksActive = () => {
          let position = window.scrollY + 200;
          navbarlinks.forEach((navbarlink) => {
            if (!navbarlink.hash) return;
            let section = select(navbarlink.hash);
            if (!section) return;
            if (
              position >= section.offsetTop &&
              position <= section.offsetTop + section.offsetHeight
            ) {
              navbarlink.classList.add("active");
            } else {
              navbarlink.classList.remove("active");
            }
          });
        };
        window.addEventListener("load", navbarlinksActive);
        onscroll(document, navbarlinksActive);

        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
          let header = select("#header");
          let offset = header.offsetHeight;

          if (!header.classList.contains("header-scrolled")) {
            offset -= 20;
          }

          let elementPos = select(el).offsetTop;
          window.scrollTo({
            top: elementPos - offset,
            behavior: "smooth",
          });
        };

        /**
         * Toggle .header-scrolled class to #header when page is scrolled
         */
        let selectHeader = select("#header");
        if (selectHeader) {
          const headerScrolled = () => {
            if (window.scrollY > 100) {
              selectHeader.classList.add("header-scrolled");
            } else {
              selectHeader.classList.remove("header-scrolled");
            }
          };
          window.addEventListener("load", headerScrolled);
          onscroll(document, headerScrolled);
        }

        /**
         * Back to top button
         */
        let backtotop = select(".back-to-top");
        if (backtotop) {
          const toggleBacktotop = () => {
            if (window.scrollY > 100) {
              backtotop.classList.add("active");
            } else {
              backtotop.classList.remove("active");
            }
          };
          window.addEventListener("load", toggleBacktotop);
          onscroll(document, toggleBacktotop);
        }

        /**
         * Mobile nav toggle
         */
        on("click", ".mobile-nav-toggle", function (e) {
          select("#navbar-public").classList.toggle("navbar-mobile");
          this.classList.toggle("fa-bars");
          this.classList.toggle("fa-xmark");
        });

        /**
         * Mobile nav dropdowns activate
         */
        on(
          "click",
          ".navbar-public .dropdown > a",
          function (e) {
            if (select("#navbar-public").classList.contains("navbar-mobile")) {
              e.preventDefault();
              this.nextElementSibling.classList.toggle("dropdown-active");
            }
          },
          true
        );

        /**
         * Scrool with ofset on links with a class name .scrollto
         */
        on(
          "click",
          ".scrollto",
          function (e) {
            if (select(this.hash)) {
              e.preventDefault();

              let navbar = select("#navbar-public");
              if (navbar.classList.contains("navbar-mobile")) {
                navbar.classList.remove("navbar-mobile");
                let navbarToggle = select(".mobile-nav-toggle");
                navbarToggle.classList.toggle("fa-bars");
                navbarToggle.classList.toggle("fa-xmark text-white");
              }
              scrollto(this.hash);
            }
          },
          true
        );

        /**
         * Scroll with ofset on page load with hash links in the url
         */
        window.addEventListener("load", () => {
          if (window.location.hash) {
            if (select(window.location.hash)) {
              scrollto(window.location.hash);
            }
          }
        });

        /**
         * Porfolio isotope and filter
         */
        window.addEventListener("load", () => {
          let portfolioContainer = select(".portfolio-container");
          if (portfolioContainer) {
            let portfolioIsotope = new Isotope(portfolioContainer, {
              itemSelector: ".portfolio-item",
            });

            let portfolioFilters = select("#portfolio-flters li", true);

            on(
              "click",
              "#portfolio-flters li",
              function (e) {
                e.preventDefault();
                portfolioFilters.forEach(function (el) {
                  el.classList.remove("filter-active");
                });
                this.classList.add("filter-active");

                portfolioIsotope.arrange({
                  filter: this.getAttribute("data-filter"),
                });
              },
              true
            );
          }
        });
      })();
    },
  };
</script>

<style>
  #header {
    height: 70px;
    z-index: 997;
    transition: all 0.5s;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: #cce8ed;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #header.header-scrolled {
    height: 70px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(3px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Logo style */
  #header .logo {
    display: flex;
    align-items: center;

  }

  #header .logo h1 {
    font-size: 36px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 70px;
  }

  @media (max-width: 991px) {
    #header .logo h1 {
      font-size: 28px;
    }
  }

  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #00366f;
    text-decoration: none;
  }

  #header .logo img {
    padding: 0;
    max-height: 24px;
  }

  .navbar-public {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-public ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar-public li {
    position: relative;
    margin: 0 15px;
    color: white;
  }

  .navbar-public a,
  .navbar-public a:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center content within the link */
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar-public a i,
  .navbar-public a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar-public a:hover,
  .navbar-public .active,
  .navbar-public .active:focus,
  .navbar-public li:hover>a {
    color: #333;
  }

  /* Dropdown Styles */
  .navbar-public .dropdown ul {
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }

  .navbar-public .dropdown ul li {
    min-width: 200px;
  }

  .navbar-public .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    text-transform: none;
  }

  .navbar-public .dropdown ul a i {
    font-size: 12px;
  }

  .navbar-public .dropdown ul a:hover,
  .navbar-public .dropdown ul .active:hover,
  .navbar-public .dropdown ul li:hover>a {
    color: #007bff;
  }

  .navbar-public .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar-public .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar-public .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }

  /* Mobile Navigation */
  .mobile-nav-toggle {
    color: red;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    margin-right: 10px;
    transition: 0.5s;
  }

  .mobile-nav-toggle.fa-xmark {
    color: white;
    margin-top: 15px;
  }

  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }

    .navbar-public ul {
      display: none;
    }
  }

  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(234, 11, 11, 0.407);
    transition: 0.3s;
    z-index: 999;
  }

  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    border-radius: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #333;
  }

  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #333;
  }

  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }

  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }

  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }

  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }

  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #007bff;
  }

  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }
</style>