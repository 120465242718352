var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-wrapper layout-navbar-full layout-horizontal layout-without-menu"
  }, [_c('div', {
    staticClass: "layout-container"
  }, [_c('header', {
    staticClass: "shadow-none d-flex align-items-center",
    attrs: {
      "id": "header"
    }
  }, [_c('div', {
    staticClass: "container d-flex justify-content-between",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('b-img', {
    staticClass: "w-full ml-4",
    staticStyle: {
      "transform": "scale(2.5)"
    },
    attrs: {
      "src": _vm.img,
      "alt": "",
      "rounded": ""
    }
  })], 1)])]), _c('nav', {
    staticClass: "navbar-public",
    attrs: {
      "id": "navbar-public"
    }
  }, [_c('ul', {
    staticClass: "ul"
  }, [_c('li', {
    staticClass: "li",
    staticStyle: {
      "margin": "0 10px"
    }
  }, [_c('b-link', {
    staticClass: "nav-link scrollto",
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'home'
      }
    }
  }, [_c('strong', [_vm._v("Beranda")])])], 1), _c('li', {
    staticClass: "li",
    staticStyle: {
      "margin": "0 10px"
    }
  }, [_c('b-link', {
    staticClass: "nav-link scrollto",
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'paket-tryout'
      }
    }
  }, [_c('strong', [_vm._v("Kelas")])])], 1), _c('li', {
    staticClass: "li",
    staticStyle: {
      "margin": "0 10px"
    }
  }, [_c('b-link', {
    staticClass: "nav-link scrollto",
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'artikel'
      }
    }
  }, [_c('strong', [_vm._v("Mading")])])], 1), _c('li', {
    staticClass: "li mr-1",
    staticStyle: {
      "margin": "0 10px"
    }
  }, [_c('b-link', {
    staticClass: "nav-link scrollto",
    staticStyle: {
      "color": "black"
    },
    attrs: {
      "to": {
        name: 'e-book'
      }
    }
  }, [_c('strong', [_vm._v("E-book")])])], 1), _c('user-dropdown', {
    staticClass: "mr-2"
  })], 1), _c('i', {
    staticClass: "fa-solid fa-bars mobile-nav-toggle mt-1"
  })])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }